<template>
  <v-dialog
    v-model="display"
    width="800"
    @keydown.esc.stop.prevent="$emit('close')"
    @click:outside="$emit('close')"
  >
    <v-card aut-edit-content-dialog>
      <DialogTitle @close="$emit('close')" title="Configure Form" />
      <v-card-text>
        <v-form ref="form">
          <v-tabs v-model="tabModel">
            <v-tab ref="default" aut-tab-default>Display</v-tab>
            <v-tab ref="config" aut-tab-look>Look and Feel</v-tab>
            <v-tab ref="data" aut-tab-data-api>Data API</v-tab>
            <v-tab ref="submit" aut-tab-submit-api>Submit API</v-tab>
            <v-tab ref="actions" aut-tab-actions>Actions</v-tab>
            <v-tab
              ref="permissions"
              aut-tab-permissions
              v-if="nonEditorPermissionsAllowed"
              >Permissions</v-tab
            >
          </v-tabs>
          <v-tabs-items v-model="tabModel">
            <v-tab-item>
              <v-row dense>
                <v-col cols="12">
                  <v-radio-group
                    v-model="formDefinition.definition.mode"
                    label="Mode"
                    dense
                    row
                    persistent-hint
                    hint="This controls how the form's contents are displayed"
                  >
                    <v-radio
                      :aut-edit-form-mode="option.value"
                      v-for="(option, i) in modes"
                      :key="i"
                      :label="option.label"
                      :value="option.value"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-radio-group
                    v-model="formDefinition.display.width"
                    label="Width"
                    dense
                    row
                    persistent-hint
                    hint="This controls how much width the form takes up"
                  >
                    <v-radio
                      :aut-edit-form-width="option.value"
                      v-for="(option, i) in widths"
                      :key="i"
                      :label="option.label"
                      :value="option.value"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-container>
                <v-row dense>
                  <v-col>
                    <FieldSettings
                      :preview="false"
                      :field="formTypeDefinition"
                      :definition.sync="formDefinition"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item>
              <APIDesigner
                :definition.sync="formDefinition.apis.data"
                type="data"
              />
            </v-tab-item>
            <v-tab-item>
              <APIDesigner
                :definition.sync="formDefinition.apis.submit"
                type="submit"
                :label="true"
              />
            </v-tab-item>
            <v-tab-item>
              <ActionsDesigner
                :definition.sync="formDefinition.definition.actions"
                @update:definition="updateActions"
              />
            </v-tab-item>
            <v-tab-item v-if="nonEditorPermissionsAllowed">
              <PermissionNode :permissions.sync="formDefinition._permissions" />
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('close')" aut-action-edit-form-cancel>
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          @click="updateDefinition"
          aut-action-edit-content-update
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { dialogMixin } from "@/components/mixin.js";
import { snakeCase, defaultsDeep } from "lodash";
import { contentModes, widths } from "@/util.js";
import Actions from "@/components/fields/Actions";
import APIDesigner from "@/components/fields/Action/APIDesigner.vue";
import FieldSettings from "@/components/fields/Field/FieldSettings/FieldSettings.vue";
import formTypeDefinition from "@/components/pageContent/Form/definition";

const debug = require("debug")("atman.components.edit_form_dialog"); // eslint-disable-line 

export default {
  name: "EditFormDialog",
  mixins: [dialogMixin],
  props: {
    definition: {
      type: Object,
    },
    tab: {
      type: String,
      default: "default",
    },
  },
  components: {
    ActionsDesigner: Actions.designer,
    APIDesigner,
    FieldSettings,
    PermissionNode: () => import("@/components/editor/PermissionNode"),
  },
  computed: {
    nonEditorPermissionsAllowed() {
      return this.isFeatureEnabled("permissions.non_editor.enabled");
    },
  },
  data() {
    const formDefinition = defaultsDeep({}, this.definition, {
      permissions: {
        enabled: false,
      },
      apis: {
        data: {
          url: "",
          type: "get",
        },
        submit: {
          url: "",
          type: "post",
        },
      },
      display: {
        width: "12",
      },
      definition: {
        actions: [],
      },
    });
    return {
      formTypeDefinition,
      formDefinition,
      display: true,
      tabModel: null,
    };
  },
  created() {
    this.snakeCase = snakeCase;
    this.modes = contentModes;
    this.widths = widths;
  },
  mounted() {
    debug(`In mounted of EditFormDialog`, this.definition, this.tab);
    this.$refs[this.tab].$el.click();
  },
  methods: {
    updateDefinition() {
      if (!this?.formDefinition?.apis?.data?.url) {
        this.formDefinition.apis.data = {};
      }
      if (!this?.formDefinition?.apis?.submit?.url) {
        this.formDefinition.apis.submit = {};
      }
      debug(`updated definition:`, this.formDefinition);
      this.$emit("update:definition", this.formDefinition);
      this.$emit("close");
    },
    updateActions(actions) {
      debug(`updateActions invoked`, actions);
      this.formDefinition.definition.actions = actions;
    },
  },
};
</script>
